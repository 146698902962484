import brand from "./brand.png";
import brandTrans from "./brand-trans.png";
// carousel
import carousel1 from "./carousel/carousel-1.jpg";
import carousel2 from "./carousel/carousel-2.jpg";
import carousel3 from "./carousel/carousel-3.jpg";
import carousel4 from "./carousel/carousel-4.jpg";
import carousel5 from "./carousel/carousel-5.jpg";
import carousel6 from "./carousel/carousel-6.jpg";
import carousel7 from "./carousel/carousel-7.jpg";
import carousel8 from "./carousel/carousel-8.jpg";
import carousel9 from "./carousel/carousel-9.jpg";
// partners
import partner1 from "./partners/partner1.jpg";
import partner2 from "./partners/partner2.jpg";
import partner3 from "./partners/partner3.jpg";
import partner4 from "./partners/partner4.jpg";
import partner5 from "./partners/partner5.jpg";
import partner6 from "./partners/partner6.jpg";
import partner7 from "./partners/partner7.jpg";
import partner8 from "./partners/partner8.jpg";
import partner9 from "./partners/partner9.jpg";
import partner10 from "./partners/partner10.jpg";
import partner11 from "./partners/partner11.jpg";
import partner12 from "./partners/partner12.jpg";
import partner13 from "./partners/partner13.jpg";
// team
import david from "./us/david.jpg";
import laleh from "./us/laleh.jpg";
import samad from "./us/samad.png";
import hamideh from "./us/hamideh.png";
import mohamadi from "./us/mohamadi.jpeg";
import miwa from "./us/miwa.jpeg";
import golnaz from "./us/golnaz.jpeg";
import mona from "./us/mona.jpeg";
import negar from "./us/negar.jpeg";
import ajai from "./us/ajai.jpeg";
import esther from "./us/esther.jpeg";
import queenrose from "./us/Queenrose.jpeg";
import reza from "./us/reza.jpg";
import pourya from "./us/pourya.png";
import elham from "./us/elham.png";
import farnaz from "./us/farnaz.jpg";
import damandeep from "./us/damandeep.jpg";
import barrister from "./us/Barrister.jpeg";
import taraneh from "./us/taraneh.jpeg";
import aniedi from "./us/Aniedi.jpeg";
import paulAweleNti from "./us/PaulAweleNti.jpeg";
import bahador from "./us/bahador.jpg";
import farzam from "./us/farzam.jpg";
import simin from "./us/simin.jpg";
import rana from "./us/rana.jpg";
import ramin from "./us/ramin.jpg";
import mitra from "./us/mitra.jpg";
import amanda from "./us/amanda.jpg";
import ada from "./us/ada.jpg";
import mahdi from "./us/mahdi.jpg";
import omareSheilaKonne from "./us/OmareSheilaKonne.jpeg";
// other
import counsel from "./banners/counsel.jpg";
import contact from "./banners/contact.png";
import services from "./banners/services.jpg";
import canadaFlag from "./canada-flag.png";
import nigeriaFlag from "./nigeria-flag.png";

const images = {
  common: {
    // team
    david,
    laleh,
    ramin,
    samad,
    hamideh,
    mohamadi,
    miwa,
    golnaz,
    mona,
    farnaz,
    damandeep,
    negar,
    ajai,
    esther,
    queenrose,
    reza,
    pourya,
    elham,
    barrister,
    taraneh,
    aniedi,
    simin,
    bahador,
    farzam,
    amanda,
    mitra,
    mahdi,
    rana,
    ada,
    paulAweleNti,
    omareSheilaKonne,
    // brand
    brand,
    brandTrans,
    // carousel
    carousel1,
    carousel2,
    carousel3,
    carousel4,
    carousel5,
    carousel6,
    carousel7,
    carousel8,
    carousel9,
    // banners
    partner1,
    partner2,
    partner3,
    partner4,
    partner5,
    partner6,
    partner7,
    partner8,
    partner9,
    partner10,
    partner11,
    partner12,
    partner13,
    // other
    counsel,
    services,
    contact,
    nigeriaFlag,
    canadaFlag,
  },
};
export default images;
